import {Select, Tooltip} from "antd";
import React, {useState, useEffect} from "react";
import SessionStorage from "../../utils/sessionStorage";
import {useMercuryContext} from "../../user-context";
import {InfoCircleOutlined} from "@ant-design/icons";

const store = new SessionStorage( "productTypesValues" );

export default function( props ) {
  const
    context = useMercuryContext(),
    [ loading, setLoadingState ] = useState( true ),
    [ errored, setErrorState ] = useState( false ),
    [ TypeValues, setTypeValues ] = useState( [] ),
    values = props.value || [];

  useEffect( () => {
    if ( !store.data && TypeValues.length === 0 ) {
      context.legacyClient.get("/productTypes")
        .then((res) => {
          store.data = res.data;
          setTypeValues(res.data);
          setLoadingState( false );
          setErrorState( false );
        })
        .catch( ( e ) => {
          console.error( e );
          setErrorState( true );
        })
    }
    else {
      setTypeValues( store.data )
      setLoadingState( false );
    }
    return;
  }, [ TypeValues.length, context.legacyClient ])

  const data = TypeValues;

  return (
    <div id="productsFilter" className={"filter-wrapper"}>
      <label className="label">Product { props.disabled && <Tooltip
        title={
          <div>
            <p style={{ marginBottom: "3px" }}>
              Not available while product area filter is in use.
            </p>
          </div>
        }
        placement="right"
        color={"var(--tertiary-color)"}
        overlayInnerStyle={{ width: "448px" }}
      >
        <InfoCircleOutlined
          style={{
            marginLeft: "5px",
            color: "var(--tertiary-color)",
            cursor: "help",
          }}
        />
      </Tooltip> }</label>
      <div style={{ display: "flex" }}>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          loading={loading}
          status={errored ? "error" : undefined }
          showSearch
          allowClear
          autoClearSearchValue={false}
          placeholder="Please select"
          value={values}
          disabled={props.disabled}
          onChange={( e ) => {
            return props.onChange( {
              target: {
                name: props.name ? props.name : "product",
                value: e
              } } )
          }}
        >
          { data && data.map( ( item ) => {
            return <Select.Option key={item._id} value={item.title}>
              {item.title}
            </Select.Option>
          })}
        </Select>
      </div>
    </div>
  )
}
