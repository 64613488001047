export default class SessionStorage {

  constructor( key ) {
    this.key = key;
  }

  get data() {

    const data = window.localStorage.getItem( this.key );

    if ( !data || data === "undefined" ) {
      return undefined;
    }

    try {
      return JSON.parse( window.localStorage.getItem( this.key ) );
    }
    catch (e) {
      return undefined;
    }
  }

  set data( value ) {

    if ( !value ) {
      return;
    }

    try {
      window.localStorage.setItem( this.key, JSON.stringify( value ) );
    }
    catch (e) {
      console.error( e );
      return;
    }
  }

  get() {
    return this.data;
  }

  set(value) {
    this.data = value;
    return this.data;
  }
}
