import React, {Component} from "react";
import {Button, Modal, Progress, Table} from "antd";
import {CheckCircleOutlined, QuestionCircleOutlined, SyncOutlined} from "@ant-design/icons";
import "./InvoiceSubmissionModal.scss";
import {sorter} from "../../../utils/helpers";
import InvoiceSubmission from "./InvoiceSubmission";

export default class InvoiceSubmissionModal extends Component {

  title = "Invoice submission";

  warning = <p>The invoices listed below are being submitted to Google. <strong>Please do not close the window or naviagte away from the page until the process is complete.</strong></p>;

  columns = [
    {
      title: 'Project ID',
      sorter: ( a, b ) => sorter( a.shortId, b.shortId ),
      dataIndex: 'shortId',
      key: 'id'
    },
    {
      title: 'Status',
      sorter: ( a, b ) => sorter( a.state, b.state ),
      // sortOrder: "descend",
      dataIndex: 'state',
      key: 'state'
    },
    {
      title: 'Result',
      sorter: ( a, b ) => sorter( a.result, b.result ),
      dataIndex: 'result',
      key: 'result'
    },
    {
      title: 'PLPs processed',
      sorter: ( a, b ) => sorter( a.completed, b.completed ),
      dataIndex: 'completed',
      key: 'completed',
      render: ( text, row ) => {
        return <span>
          {row.completed} / {row.total}
        </span>
      }
    },
    {
      title: "",
      dataIndex: "state",
      key: "icon",
      render: ( text, row ) => {
        switch (text.toLowerCase()) {
          case "complete":
            return <CheckCircleOutlined />
          case "submitting":
            return <SyncOutlined spin />
          case "errored":
            return <Button onClick={() => this.submitter && this.submitter.retry(row.shortId)}>Retry</Button>
          case "pending":
          default:
            return <QuestionCircleOutlined />
        }
      }
    },
  ]

  constructor(props) {
    super( props );

    this.state = {
      rows: [],
      loading: true
    }
  }


  async listPlpsDetails( rows ) {
    const
      ids = rows.map( (r) => r.shortId ),
      { data } = await this.props.context.gpClient.post( "/google-gp-v1/plp/plpDetailsByProject", ids ),
      map = {};

    data.map( ( plp ) => {
      if ( !map[plp.projectId] ) {
        map[plp.projectId] = [];
      }

      map[plp.projectId].push( plp._id );
      return null;
    })

    return rows.map( ( row ) => {
      row.plps = map[row.shortId];
      return row;
    });
  }

  async listPlps( rows ) {
    const
      ids = rows.map( (r) => r.shortId ),
      { data } = await this.props.context.gpClient.post( "/google-gp-v1/plp/plpIdsByProject", ids ),
      map = {};

    data.map( ( plp ) => {
      if ( !map[plp.shortId] ) {
        map[plp.shortId] = [];
      }
      map[plp.shortId].push( plp.id );
      return null;
    })

    return rows.map( ( row ) => {
      row.plps = map[row.shortId];
      return row;
    });
  }

  submitter = null;

  async componentDidMount() {

    const ids = await this.listPlps( this.props.rows )

    this.setState( { loading: false } );

    const Submitter = this.submitter = new InvoiceSubmission( {
      context: this.props.context,
      projects: ids
    });

    Submitter.on( "update", ( s ) => {
      this.setState({ rows: s } );
    }  );

    await Submitter.submit();
  }

  render() {
    const
      { rows } = this.state,
      submitted = rows ? rows.filter( ( r ) => r.sent ).length : 0,
      total = rows ? rows.length : 0,
      errors = rows ? rows.filter( ( r ) => r.sent && r.state && r.state.toLowerCase() === "errored" ).length : 0,
      progress = submitted / total;

    return (
      <Modal
        className={"invoice-submitter".concat( progress !== 1 ? " hide-closable" : "" ) }
        title={this.title}
        maskClosable={progress === 1}
        open={this.props.show}
        onCancel={() => null}
        okButtonProps={
          {
            disabled: progress !== 1,
            onClick: this.props.close
          }
        }
        okText={"Close"}
        cancelButtonProps={{ style: { "display": "none"} }}
      >
        {this.warning}
        <Progress percent={Math.ceil(progress * 100)} status={ progress < 1 ? "active" : ( errors > 0 ? "exception" : null ) } className={"submission-progress"} />
        <p>Submitted {submitted} of {total}</p>
        {
          errors > 0
            ? <p style={{ textAlign: "center" }}><strong>{errors} error(s) occurred after retries. Please retry these manually</strong></p>
            : null
        }
        <div className={"dashboard"}>
          <Table
            className="submission-table projects-table"
            columns={this.columns}
            dataSource={rows}
            loading={this.state.loading}
            pagination={{
              position: ["bottomCenter"],
              defaultPageSize: 10
            }}
          />
        </div>
      </Modal>
    )
  }
}
