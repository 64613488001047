import LocalStorage from "../../../utils/localStorage";

export default class FilterStorage {

  data = new Set();

  constructor( key ) {
    this.key = key;
    this.storage = new LocalStorage( key );
    this.load();
  }

  load() {
    const data = this.storage.data;
    if ( data ) {
      this.data = new Set( data );
    }
  }

  save() {
    if ( this.data ) {
      this.storage.data = Array.from( this.data );
    }
  }

  get filters() {
    const [ data ] = Array.from( this.data );
    return data;
  }

  add( item ) {
    this.data.add( item );
    this.save();
  }

  delete( item ) {
    this.data.delete( item );
    this.save();
  }

  clear() {
    this.data = new Set();
    this.save();
  }
}
