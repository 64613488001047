import React, {Component} from "react";
import {Button, Col, Row, Select, Table, Tooltip} from "antd";
import {
  AlignLeftOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseCircleOutlined,
  CloseOutlined
} from "@ant-design/icons";
import {Helmet} from "react-helmet";
import Title from "antd/es/typography/Title";
import LoadingStar from "../../ui/LoadingStar";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import FilterStorage from "../filters/utils/FilterStorage";
import SessionStorage from "../../utils/sessionStorage";

import { Error as ErrorWarning } from '../../stories/Errors/Error'

import "./Table.abstract.scss"

export default class TableAbstract extends Component {

  column( props ) {
    return {
      ...props,
      ...( props && props.sorter === true ? {
        sortOrder: this.sortField === props.dataIndex ? this.sortOrder : undefined
      } : {} )
    }
  }

  method="GET";

  title="Untitled table";

  pageTitle="Untitled - Mercury © RWS";

  pageClass="defaultTable";

  mapping=null;

  constructor( props ) {

    super( props );

    this.context = props.context;

    this.createClient();

    this.state = {
      breaks: props.breaks,
      initialised: false,
      loading: true,
      rowData: [],
      error: undefined,
      filters: {},
      showFilters: true,
      selectedRows: [],
      currentPage: 1,
      itemsPerPage: 100,
      disabledFilters: {}
    }
  }

  get breaks() {
    return this.state.breaks;
  }

  createClient() {
    if ( this.props.useLegacyEndpoints ) {
      this.Client = this.context.legacyClient
    }
    else if ( this.props.useGpEKS ) {
      this.Client = this.context.gpClient
    }
    else {
      this.Client = this.context.client;
    }
  }

  clear = () => {
    this.filterStore.clear();
    this.filterStore.add( {} );
    this.filterStore.save();
    const data = this.tableSettingsStore.data || {};
    data.currentPage = 1;
    this.tableSettingsStore.data = data;
    this.setState( { filters: {}, currentPage: 1, selectedRows: [] }, this.getData );
  }

  reload = async () => {
    this.setState( { error: undefined })
    await this.getData();
  }

  updateFilters = ( filters ) => {
    const updated = this.disabledFilterRules( filters );
    this.filterStore.clear();
    this.filterStore.add( updated );
    this.filterStore.save();

    this.setState( { filters: updated  }, this.getData );
  }

  disabledFilterRules( filters ) {
    return filters;
  }

  componentDidMount() {

    this.filterStore = new FilterStorage( this.title );
    this.tableSettingsStore = new SessionStorage( this.title.concat( "-settings" ) );

    const { itemsPerPage, currentPage, sortField, sortOrder } = this.tableSettingsStore.data || {
      itemsPerPage: 100,
      currentPage: 1
    };

    const filters = this.filterStore.filters ? this.disabledFilterRules( this.filterStore.filters ) : {};

    this.setState({
      filters,
      itemsPerPage,
      currentPage,
      sortOrder,
      sortField
    }, () => {
      this.getData()
        .then( () => this.setState( { initialised: true, loading: false } ) )
        .catch( ( e ) => {
          console.error( "data exeception: ", e );
          this.setState( { error: e } );
        } )
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( JSON.stringify( this.state.breaks ) !== JSON.stringify( this.props.breaks ) ) {
      this.setState( { breaks: this.props.breaks });
    }
  }

  endpoint() {
    return "/projects"
  }

  uri() {
    if ( this.method.toUpperCase() === "GET" ) {
      return this.endpoint()
        .concat( "?" )
        .concat(
          Object.keys(this.state.filters)
          .map(key => key + '=' + this.state.filters[key]).join('&')
        );
    }
    return this.endpoint();
  }

  body() {
    if ( this.method.toUpperCase() === "GET" ) {
      return {}
    }
    return this.state.filters;
  }

  updateFilter( e, filters ) {
    const
      { target } = e;
    filters[target.name] = target.value;
    Object.keys( filters ).map( ( key ) => {
      if ( !filters[key] || ( Array.isArray(filters[key]) && filters[key].length === 0) ) {
        delete filters[key];
      }
      return null;
    })
  }

  get filters() {
    return this.state.filters;
  }

  set filters( value ) {
    this.updateFilters( value );
    return this.state.filters;
  }

  onUpdateFilter = ( e ) => {

    const { filters } = this.state;

    if ( Array.isArray( e ) ) {
      e.map( ( item ) => {
        this.updateFilter( item, filters );
        return null;
      })
    }
    else {
      this.updateFilter( e, filters );
    }

    this.filters = filters;
  }

  onClearFilter = ( e ) => {
    const filters = this.state.filters;
    filters[ e.target.name ] = undefined;
    Object.keys( filters ).map( ( key ) => {
      if ( !filters[key] || ( Array.isArray(filters[key]) && filters[key].length === 0) ) {
        delete filters[key];
      }
      return null;
    })

    this.filters = filters;
  }

  onRemoveFromList = ( e ) => {

    const
      filters = this.state.filters,
      { target } = e;

    if ( target.name in filters && ( Array.isArray( filters[ target.name ] ) || filters[ target.name ] instanceof Set ) ) {
      let updated = filters[target.name];
      if ( Array.isArray( filters[ target.name ] ) ) {
        updated = new Set( filters[target.name]);
        updated.delete( target.value );
      }
      else {
        updated.delete( target.value );
      }
      const arr = Array.from( updated );
      filters[target.name] = arr.length === 0 ? undefined : arr;
      Object.keys( filters ).map( ( key ) => {
        if ( !filters[key] || ( Array.isArray(filters[key]) && filters[key].length === 0) ) {
          delete filters[key];
        }
        return null;
      })
      this.updateFilters( filters );
    }
  }

  toggleFilters() {
    this.setState( { showFilters: !this.state.showFilters})
  }

  async send() {

    if ( this.abortController ) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    if ( this.method.toUpperCase() === "GET" ) {
      const res = await this.Client.get( this.uri(), { signal: this.abortController.signal } );
      return res.data;
    }

    const res = await this.Client.post( this.uri(), this.body(), { signal: this.abortController.signal } );

    return res.data;
  }

  async getData() {
    try {
      this.setState( { loading: true } );
      const data = await this.send();
      this.setState( { selectedRows: [], rowData: data, loading: false })
    }
    catch( e ) {
      console.error( e );
      this.setState( { loading: false, initialised: true, error: e })
    }
  }

  set itemsPerPage( value ) {
    this.setState({ itemsPerPage: value }, () => {
      const data = this.tableSettingsStore.data || {};
      data.itemsPerPage = value;
      this.tableSettingsStore.data = data;
      this.getData();
    } );
  }

  get itemsPerPage() {
    return this.state.itemsPerPage
  }

  set currentPage( value ) {
    this.setState( { currentPage: value }, () => {
      const data = this.tableSettingsStore.data || {};
      data.currentPage = value;
      this.tableSettingsStore.data = data;
      this.getData();
    } )
  }

  get currentPage() {
    return this.state.currentPage;
  }

  get sortOrder() {
    return this.state.sortOrder || ""
  }

  set sortOrder( sortOrder ) {
    this.setState( { sortOrder }, () => {
      const data = this.tableSettingsStore.data || {};
      data.sortOrder = sortOrder;
      this.tableSettingsStore.data = data;
      this.getData();
    } );
  }

  get sortField() {
    return this.state.sortField || ""
  }

  set sortField( sortField ) {
    this.setState( { sortField }, () => {
      const data = this.tableSettingsStore.data || {};
      data.sortField = sortField;
      this.tableSettingsStore.data = data;
      this.getData();
    } );
  }

  renderActions() {
    return null;
  }

  renderFilters() {
    return (
      <section className="filters-section" style={{ marginBottom: "20px" }} ref={this.filtersRef}>
        <TransitionGroup component={null}>
          {this.state.showFilters && (
            <CSSTransition
              classNames="fade"
              in={this.state.showFilters}
              timeout={300}
              unmountOnExit
            >
              <div
                className="filters-content"
                style={{
                  border: "1px solid #e1e7eb",
                  marginTop: "20px",
                  borderRadius: "4px",
                }}
              >
                <div className="filters-wrapper">
                  <Row
                    type="flex"
                    justify={"space-between"}
                    style={{ marginBottom: "10px" }}
                  >
                    <div className={"filters-left"}>
                    {
                      this.filterComponents.filter( ( el ) => el.left ).map( ( filter ) => {
                        return <filter.component
                          key={filter.name}
                          name={filter.name}
                          value={ Array.isArray(filter.name )
                            ? filter.name.map( name => this.state.filters[name] )
                            : this.state.filters[filter.name] }
                          onRemove={this.onRemoveFromList}
                          onChange={this.onUpdateFilter}
                          disabled={this.state.disabledFilters[filter.name]}
                          onClearFilter={this.onClearFilter}
                        />
                      })
                    }
                    </div>
                    <div className={"filters-right"}>
                    {
                      this.filterComponents.filter( ( el ) => !el.left ).map( ( filter ) => {
                        return <filter.component
                          key={filter.name}
                          name={filter.name}
                          value={ Array.isArray(filter.name )
                            ? filter.name.map( name => this.state.filters[name] )
                            : this.state.filters[filter.name] }
                          onRemove={this.onRemoveFromList}
                          onChange={this.onUpdateFilter}
                          disabled={this.state.disabledFilters[filter.name]}
                          onClearFilter={this.onClearFilter}
                          />
                      })
                    }
                    </div>
                  </Row>
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </section>
    );
  }

  filterComponents=[];

  select = ( e ) => {

    const
      { selectedRows } = this.state,
      exists = selectedRows.filter( r => r.key === e.key );

    let
      selected = [];

    if ( exists.length ) {
      selected = selectedRows.filter( r => r.key !== e.key );
    }
    else {
      selected = selectedRows.concat( [ e ] );
    }

    this.setState({ selectedRows: selected } );
  }

  selectAll = ( all ) => {
    if ( all ) {
      this.setState( { selectedRows: this.rows.map( ( item ) => { return item } ) } );
    }
    else {
      this.setState( { selectedRows: [] } );
    }
  }

  renderPagination() {

    const data = this.state.rowData;

    return (
      <Row style={{ margin: "15px 0" }} justify="center">
          <>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 22px 0 10px",
              }}
            >
              {this.currentPage > 1 ? (
                <Tooltip title="Previous page" placement={"top"}>
                  <CaretLeftOutlined
                    onClick={() => this.currentPage = this.currentPage - 1 }
                    className="pagination-button"
                  />
                </Tooltip>
              ) : (
                <CaretLeftOutlined
                  disabled
                  className="pagination-button"
                  style={{ color: "var(--grey)" }}
                />
              )}
              <Tooltip title="Current page" placement={"top"}>
                <Button
                  type="primary"
                  style={{
                    padding: "0 12px",
                    margin: "0 10px",
                    cursor: "default",
                    background: "var(--white)",
                    color: "var(--primary-color)",
                  }}
                >
                  {this.currentPage}
                </Button>
              </Tooltip>
              {this.itemsPerPage === data.length ? (
                <Tooltip title="Next page" placement={"top"}>
                  <CaretRightOutlined
                    onClick={() =>  this.currentPage = this.currentPage + 1 }
                    className="pagination-button"
                  />
                </Tooltip>
              ) : (
                <CaretRightOutlined
                  disabled
                  className="pagination-button"
                  style={{ color: "var(--grey)" }}
                />
              )}
            </Col>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <Select
                style={{ width: 110, textAlign: "center" }}
                onChange={(value) => this.itemsPerPage = parseInt( value ) }
                placeholder={`${this.itemsPerPage} / page`}
                dropdownStyle={{
                  textAlign: "center",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "var(--tertiary-color)",
                }}
              >
                <Select.Option value="25">25 / page</Select.Option>
                <Select.Option value="50">50 / page</Select.Option>
                <Select.Option value="75">75 / page</Select.Option>
                <Select.Option value="100">100 / page</Select.Option>
                <Select.Option value="125">125 / page</Select.Option>
                <Select.Option value="150">150 / page</Select.Option>
                <Select.Option value="175">175 / page</Select.Option>
                <Select.Option value="200">200 / page</Select.Option>
              </Select>
            </Col>
          </>
      </Row>
    )
  }

  renderTitle() {

    let filterHasValues = Object.keys( this.state.filters ).length > 0;

    return (
      <div className={"table-title-container"}>
        <div className={"table-title-left"}>
          <Helmet>
            <title>{this.pageTitle}</title>
          </Helmet>
          <Title
            level={3}
            className={"table-title"}
          >
            {this.title}

          </Title>
          <div className={"filter-options-container"}>
            <Tooltip
              placement="bottom"
              color={"var(--tertiary-color)"}
              title={this.state.showFilters ? "Hide filters" : "View all filters"}
            >
              <Button
                type="primary"
                onClick={() => this.toggleFilters()}
                icon={
                  !this.state.showFilters ? (
                    <AlignLeftOutlined />
                  ) : (
                    <CloseCircleOutlined />
                  )
                }
              >
                Filters
              </Button>
            </Tooltip>
            {filterHasValues && (
              <Button
                danger
                className="reset-filters"
                icon={<CloseOutlined />}
                onClick={() => {
                  this.clear();
                } }
                style={{ marginRight: "10px" }}
              >
                Clear Filters
              </Button>
            )}
          </div>

        </div>
        <div className={"action-buttons"}>
          {this.renderActions()}
        </div>
      </div>
    )
  }

  get rows() {

    let
      rows = this.state.rowData;

    if ( this.mapping ) {
      rows = this.state.rowData.map( ( row ) => {
        const translated = {};
        Object.keys( row ).map( ( key ) => {
          if ( key in this.mapping ) {
            translated[this.mapping[key]] = row[key];
          }
          else {
            translated[key] = row[key]
          }
          return null;
        } );
        return translated;
      } );
    }

    return rows.map( ( row, i ) => {

      return {
        key: row._id || i,
        ...row
      }
    });
  }

  order() {
    switch ( this.sortOrder ) {
      case "ascend":
        return "descend";
      case "descend":
      default:
        return "ascend";
    }
  }

  sort( pagination, filters, sorter, extra ) {

    const { order, field } = sorter;

    this.setState( { sortOrder: order, sortField: order !== undefined ? field : "" }, () => {
      const data = this.tableSettingsStore.data || {};
      data.sortOrder = order;
      data.sortField =  order !== undefined ? field : ""
      this.tableSettingsStore.data = data;
      this.getData();
    })
  }

  columns() {
    return [];
  }

  renderTable() {

    const
      cols = this.columns().map( ( item ) => this.column( item ) );

    return <Table
      columns={cols}
      dataSource={this.rows}
      rowSelection={{
        // preserveSelectedRowKeys: true,
        selectedRowKeys: this.state.selectedRows.map( r => r.key),
        onSelectAll: this.selectAll,
        onSelect: this.select
      }}
      pagination={false}
      onChange={( pagination, filters, sorter, extra ) => this.sort( pagination, filters, sorter, extra ) }
      className="projects-table"
      loading={this.state.loading}
      scroll={{ x: 1500 }}
      expandable={this.expandable()}
      sticky={{ offsetHeader: 40 }}
    />
  }

  render() {

    if ( !this.state.initialised ) {
      return <LoadingStar title={"Initialising..."} logo={true} />
    }

    if( this.state.error ) {
      return <ErrorWarning resetFilters={this.clear} reload={this.reload} error={this.state.error.toString()}/>
    }

    return (
      <div className={this.pageClass}>
        {this.renderTitle()}
        {this.renderFilters()}
        {this.renderTable()}
        {this.renderPagination()}
      </div>
    )
  }

}

TableAbstract.defaultProps = {
  useGpEKS: false
}