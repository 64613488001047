import { Tooltip } from "antd";
import { CustomTooltip } from "../stories/CustomTooltip/CustomTooltip";
import TimezoneConverter from "../timezone-converter";

//*** Define abstract table columns ***//

export const productColumn = {
    id: "product",
    title: "Product",
    dataIndex: "product",
    sorter: false,
    width: 200,
    render: (text: string) => {
        return <CustomTooltip text={text} length={25} />
    },
    fixed: undefined,
}

export const lastImportedTimestampColumn = {
    id: "lastImportedTimestamp",
    title: <Tooltip title="Last Imported Timestamp" placement="left" color={"var(--tertiary-color)"}>Last Imported Timestamp</Tooltip> as any,
    dataIndex: "lastImportedTimestamp",
    sorter: true,
    width: 150,
    render: (text: string) => <TimezoneConverter date={text}/>,
    fixed: undefined,
}

export const lastUpdatedTimestampColumn = {
    id: "lastUpdatedTimestamp", // from Google
    title: <Tooltip title="Last Updated Timestamp" placement="left" color={"var(--tertiary-color)"}>Last Updated Timestamp</Tooltip> as any,
    dataIndex: "lastUpdatedTimestamp",
    sorter: true,
    width: 150,
    render: (text: string) => <TimezoneConverter date={text}/>,
    fixed: undefined,
};

export const creationTimestampColumn = {
    id: "creationTimestamp",
    title: <Tooltip title="Creation Timestamp" placement="left" color={"var(--tertiary-color)"}>Creation Timestamp</Tooltip> as any,
    dataIndex: "creationTimestamp",
    sorter: true,
    width: 150,
    render: (text: string) => <TimezoneConverter date={text}/>,
    fixed: undefined,
};