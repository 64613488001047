import {Select} from "antd";
import React, {useState, useEffect} from "react";
import SessionStorage from "../../utils/sessionStorage";
import {useMercuryContext} from "../../user-context";

const store = new SessionStorage( "productAreaValues" );

export default function( props ) {
  const
    context = useMercuryContext(),
    [ loading, setLoadingState ] = useState( true ),
    [ errored, setErrorState ] = useState( false ),
    [ ProductAreaValues, setProductAreaValues ] = useState( [] ),
    values = props.value || [];

  useEffect( () => {
    if ( !store.data && ProductAreaValues.length === 0 ) {
      context.legacyClient.get("/productArea")
        .then((res) => {
          store.data = res.data;
          setProductAreaValues(res.data);
          setLoadingState( false );
          setErrorState( false );
        })
        .catch( ( e ) => {
          console.error( e );
          setErrorState( true );
        })
    }
    else {
      setProductAreaValues( store.data );
      setLoadingState( false );
    }
    return;
  }, [ProductAreaValues.length,context.legacyClient])

  const data = ProductAreaValues;

  return (
    <div id="productAreaFilter" className={"filter-wrapper"}>
      <label className="label">Product Area</label>
      <div style={{ display: "flex" }}>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          showSearch
          allowClear
          autoClearSearchValue={false}
          placeholder="Please select"
          value={values}
          loading={loading}
          status={errored ? "error" : undefined }
          onChange={( e ) => {
            return props.onChange( {
              target: {
                name: props.name ? props.name : "product",
                value: e
              } } )
          }}
        >
          { data && data.map( ( item ) => {
            return <Select.Option key={item._id} value={item.title}>
              {item.title}
            </Select.Option>
          })}
        </Select>
        {/*{productArea.length > 1 && (*/}
        {/*  <Tooltip title="Remove all products">*/}
        {/*    <Button*/}
        {/*      size={"small"}*/}
        {/*      onClick={handleClearProductArea}*/}
        {/*      icon={<CloseOutlined />}*/}
        {/*      style={{*/}
        {/*        border: "none",*/}
        {/*        background: "transparent",*/}
        {/*        padding: "5px 0 5px 5px",*/}
        {/*      }}*/}
        {/*    ></Button>*/}
        {/*  </Tooltip>*/}
        {/*)}*/}
      </div>
    </div>
  )
}
