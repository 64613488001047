import React from "react";
import {
    DateFilter,
    CustomTooltip
} from "../../Components";

import {
    projectIdFilter,
    productFilter,
    sourceLanguageCodeFilter,
    targetLanguageCodeFilter,
    wordRateFilter,
    wordChargeFilter,
    serviceRateFilter,
    serviceHoursFilter,
    serviceChargeFilter,
    dtpRateFilter,
    dtpHoursFilter,
    dtpChargeFilter,
    engineeringRateFilter,
    engineeringHoursFilter,
    engineeringChargeFilter,
    pmFeeRateFilter,
    pmFeeChargeFilter,
    sharingStatusFilter,
    lastImportedTimestampFilter,
    lastUpdatedTimestampFilter,
    creationTimestampFilter
} from '.././filtersConfig';

import NumberFilter from "../MongoNumber.filter";
import {useMercuryContext} from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {Tooltip} from "antd"
import {Link} from "react-router-dom";
import {BlockOutlined, InfoCircleOutlined} from "@ant-design/icons";
import SharingPercentage from "../../ui/SharingPercentage";
import TimezoneConverter from "../../timezone-converter";

import UrgencyFilter from "../Urgency.filter";
import ComponentTypeFilter from "../ComponentType.filter";
import StatusFilter from "../Status.filter";
import RateNameFilter from "../RateName.filter";
import {AlignType} from "rc-table/lib/interface";
import CurrencyFormat from "react-currency-format"
import VarStatusFilter from "../VarStatus.filter";
import { DateTime } from "luxon";
import BatchHours from "../Batch.hours";

import "./PLP.scss"

import PolyglotLogo from '../../stories/assets/polyglot-logo.svg'
import TableWSAbstract from "../../stories/Table/TableWS.abstract";
import {TableProps} from "antd/lib/table";
import {TableState} from "../../stories/Table/TableBase";
import uuid from "uuid-browser";
import { creationTimestampColumn, lastImportedTimestampColumn, lastUpdatedTimestampColumn } from "../columnsConfig";
import {InvoicingBulkAction} from "../InvoicingBulk.action";

class PLPSearch extends TableWSAbstract<any> {
    defaultSortField = "importedTimestamp";
    FilterComponents = [
        projectIdFilter,
        productFilter,
        sourceLanguageCodeFilter,
        targetLanguageCodeFilter,
        {
            id: "totalCharge",
            name: "totalCharge",
            title: "PLP Invoice amount",
            component: NumberFilter,
            active: true
        },
        {
            id: "varStatus",
            name: "varStatus",
            title: "Invoice Status",
            component: VarStatusFilter,
            active: true
        },
        wordRateFilter,
        wordChargeFilter,
        serviceRateFilter,
        serviceHoursFilter,
        serviceChargeFilter,
        dtpRateFilter,
        dtpHoursFilter,
        dtpChargeFilter,
        engineeringRateFilter,
        engineeringHoursFilter,
        engineeringChargeFilter,
        pmFeeRateFilter,
        pmFeeChargeFilter,
        sharingStatusFilter,
        {
            id: "status",
            name: "catToolStatus",
            title: "Status",
            component: StatusFilter,
            active: true
        },
        {
            id: "urgency",
            name: "urgency",
            title: "Urgency",
            component: UrgencyFilter,
            active: true
        },
        {
            id: "componentType",
            name: "componentType",
            title: "Component Type",
            component: ComponentTypeFilter,
            active: false
        },
        // {
        //     id: "assignedVendor", // Redundant filter
        //     name: "assignedVendor",
        //     title: "Assigned Vendor",
        //     component: AssignedVendorFilter,
        //     active: false
        // },
        // {
        //     id: "title",
        //     name: "title",
        //     title: "Title",
        //     component: TextFilter,
        //     active: false
        // },
        {
            id: "receivedDate",
            name: "importedDate",
            title: "Received Date",
            component: DateFilter,
            active: true
        },
        {
            id: "latestTranslationDueTimestamp",
            name: "latestTranslationDueTimestamp",
            title: "Due Date",
            component: DateFilter,
            active: true
        },
        {
            id: "totalWeightedWordCount",
            name: "twwc",
            title: "Total Weighted Word Count",
            component: NumberFilter,
            active: false
        },
        {
            id: "rateNameApplied",
            name: "rateNameApplied",
            title: "Rate Name",
            component: RateNameFilter,
            active: false
        },
        {
            id: "totalWordCount",
            name: "wc",
            title: "Total Word Count",
            component: NumberFilter,
            active: false
        },
        creationTimestampFilter,
        lastImportedTimestampFilter,
        lastUpdatedTimestampFilter
    ];
    columns: any = [
        {
            id: "projectId",
            title: "Project ID",
            dataIndex: "shortId",
            sorter: true,
            width: 140,
            fixed: undefined,
            render: (id: string, record: any) => {
                return (
                    <>
                        <a
                            href={`/project/${id}/?type=projectId`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <BlockOutlined style={{marginRight:'3px'}}/>
                        </a>
                        <Link
                            to={`/project/${id}?type=projectId`}
                            id={id}
                        >
                            {id}
                        </Link>
                        { record.customVendorPLPMetadata?.projectType !== 'WMT' && <a
                            href={`https://localization.google.com/polyglot?project_id=${id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title='Polyglot Link'
                            style={{userSelect: 'none'}}
                        >
                            <img src={PolyglotLogo} alt="polyglot" style={{marginLeft: '3px', width: 'auto', height: '20px'}}/>
                        </a> }
                    </>
                )
            }
        },
        {
            id: "title",
            title: "Title",
            dataIndex: "projectTitle",
            width: 400,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={57}/>,
        },
        {
            id: "product",
            title: "Product",
            dataIndex: "product",
            sorter: true,
            width: 200,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={25}/>,
        },
        {
            id: "sourceLanguageCode",
            title: <Tooltip title="Source Language" placement="left" color={"var(--tertiary-color)"}>Source La...</Tooltip> as any,
            dataIndex: "sourceLanguageCode",
            sorter: true,
            width: 105,
            fixed: undefined,
        },
        {
            id: "targetLanguageCode",
            title: <Tooltip title="Target Language" placement="left" color={"var(--tertiary-color)"}>Target La...</Tooltip> as any,
            dataIndex: "targetLanguageCode",
            sorter: true,
            width: 105,
            fixed: undefined,
        },
        {
            id: "urgency",
            title: "Urgency",
            dataIndex: "turnaroundTimeUrgency",
            sorter: true,
            width: 100,
            fixed: undefined,
        },
        {
            id: "totalCharge",
            title: "PLP Invoice Amount",
            dataIndex: "totalCharge",
            sorter: true,
            width: 160,
            fixed: undefined,
            render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={20}/></span>} />
        },
        {
            id: "varStatus",
            title: "Invoice Status",
            dataIndex: "varStatus",
            sorter: true,
            width: 163,
            fixed: undefined,
            render: (text: string, record:any) => {

                if (
                    Array.isArray(record.invoiceData?.invoiceErrors) &&
                    record.invoiceData?.invoiceErrors.length > 0
                ) {
                    return record.varStatus !== "ACCRUAL" && record.varStatus !== "ESTIMATE" && record.varStatus !== "INVOICE" ? (
                        <Tooltip
                            title={record.invoiceData?.invoiceErrors.reduce((errorMessage:string, error:string) => {
                                return (errorMessage += error + "\r\n");
                            }, "")}
                            placement="right"
                            color={"var(--red)"}
                        >
                            {text}
                            <InfoCircleOutlined
                                style={{ color: "var(--red)", marginLeft: "3px" }}
                            />
                        </Tooltip>
                    ) : (
                        text
                    );
                } else {
                    return text;
                }
            }
        },
        {
            id: "rateNameApplied",
            title: "Rate Name",
            dataIndex: "rateNameApplied",
            sorter: true,
            width: 120,
            fixed: undefined,
            align: "center" as AlignType,
            render: (text: string) => <CustomTooltip text={text} length={17}/>
        },
        {
            id: "wordRate",
            title: "Word Rate",
            dataIndex: "wordRate",
            sorter: true,
            width: 100,
            fixed: undefined,
        },
        {
            id: "wordCharge",
            title: "Word Charge",
            dataIndex: "wordCharge",
            sorter: true,
            width: 120,
            fixed: undefined,
            render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
        },
        {
            id: "serviceRate",
            title: "Service Rate",
            dataIndex: "serviceRate",
            sorter: true,
            width: 115,
            fixed: undefined,
            render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
        },
        {
            id: "serviceHours",
            title: "Service Hours",
            dataIndex: "serviceHours",
            sorter: true,
            width: 120,
            fixed: undefined,
        },
        {
            id: "serviceCharge",
            title: "Service Charge",
            dataIndex: "serviceCharge",
            sorter: true,
            width: 110,
            fixed: undefined,
            render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
        },
        {
            id: "dtpRate",
            title: "DTP Rate",
            dataIndex: "dtpRate",
            sorter: true,
            width: 90,
            fixed: undefined,
            render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
        },
        {
            id: "dtpHours",
            title: "DTP Hours",
            dataIndex: "dtpHours",
            sorter: true,
            width: 105,
            fixed: undefined,
        },
        {
            id: "dtpCharge",
            title: "DTP Charge",
            dataIndex: "dtpCharge",
            sorter: true,
            width: 110,
            fixed: undefined,
            render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
        },
        {
            id: "engineeringRate",
            title: "Engineering Rate",
            dataIndex: "engineeringRate",
            sorter: true,
            width: 145,
            fixed: undefined,
            render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
        },
        {
            id: "engineeringCharge",
            title: "Engineering Charge",
            dataIndex: "engineeringCharge",
            sorter: true,
            width: 160,
            fixed: undefined,
            render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
        },
        {
            id: "engineeringHours",
            title: <Tooltip title="Engineering Hours" placement="left" color={"var(--tertiary-color)"}>Eng Hours...</Tooltip> as any,
            dataIndex: "engineeringHours",
            sorter: true,
            fixed: undefined,
            width: 105
        },
        // {
        //     id: "invoiceName", // TODO: will be added at a later date
        //     title: "Invoice Number",
        //     dataIndex: "invoiceName", // TODO: point to customVendorProjectMetadata.invoiceName
        //     sorter: true,
        //     width: 150,
        //     render: (text: string) => <CustomTooltip text={text} length={17}/>,
        // },
        // {
        //     id: "owner", // TODO: will be added at a later date
        //     title: "Owner",
        //     dataIndex: "ownerName",
        //     width: 260,
        //     align: "left" as AlignType,
        //     sorter: true,
        //     fixed: undefined,
        //     render: (text: any, record: any) => <UserBadge name={record.ownerName} email={record.ownerEmail} avatarSize={22} hideEmail={true} />
        // },
        {
            id: "pmFeeRate",
            title: "PM Fee Rate",
            dataIndex: "pmFeeRate",
            sorter: true,
            width: 110,
            fixed: undefined,
            render: ( text:string ) => <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
        },
        {
            id: "pmFeeCharge",
            title: "PM Fee Charge",
            dataIndex: "pmFeeCharge",
            sorter: true,
            width: 125,
            fixed: undefined,
            render: ( text:string ) => !text ? <span className="currency">{"$0.00"}</span> : <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value:any) => <span className={"currency"}><CustomTooltip text={value} length={16}/></span>} />
        },
        {
            id: "totalWeightedWordCount",
            title: <Tooltip title="Total Weighted Word Count" placement="left" color={"var(--tertiary-color)"}>Total Wei...</Tooltip> as any,
            dataIndex: "twwc",
            // dataIndex: "totalWeightedWordCount", // old key
            sorter: true,
            width: 105,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={15}/>,
        },
        {
            id: "totalWordCount",
            title: <Tooltip title="Total Word Count" placement="left" color={"var(--tertiary-color)"}>Total Wor...</Tooltip> as any,
            dataIndex: "wc",
            // dataIndex: "totalWordCount", // old key
            sorter: true,
            fixed: undefined,
            width: 105
        },
        {
            id: "componentType",
            title: "Component Type",
            dataIndex: "componentType",
            sorter: true,
            width: 150,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={17}/>,
        },
        {
            id: "importedTimestamp",
            title: "Received Date",
            dataIndex: "importedTimestamp",
            sorter: true,
            width: 150,
            fixed: undefined,
            render: (text: string) => <TimezoneConverter date={text}/>
        },
        // {
        //     id: "highestDueDateTier", // Redundant column
        //     title: "Due Date Tier",
        //     dataIndex: "highestDueDateTier",
        //     sorter: true,
        //     width: 115,
        //     render: (text: string) => <CustomTooltip text={text} length={15}/>,
        // },
        {
            id: "latestTranslationDueTimestamp",
            title: "Due Date",
            dataIndex: "latestTranslationDueTimestamp",
            sorter: true,
            width: 150,
            fixed: undefined,
            render: (text: string) => <TimezoneConverter date={text}/>
        },
        {
            id: "catToolStatus",
            title: "Status",
            dataIndex: "catToolStatus",
            sorter: true,
            width: 130,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={15}/>
        },
        {
            id: "sharingStatus",
            title: "Sharing",
            dataIndex: "sharingStatus",
            sorter: true,
            width: 130,
            fixed: undefined,
            align: "center" as AlignType,
            render: (text: string) => <SharingPercentage percentage={text} date={undefined}/>,
        },
        creationTimestampColumn,
        lastImportedTimestampColumn,
        lastUpdatedTimestampColumn
    ];

    // renderActions() {
    //     // return <div className="actions"><Button type='primary'>Batch Update</Button></div>
    //     return null;
    // }

    renderActions() {
        return [
            <BatchHours
                key={"batchHours"}
              selectedRows={this.state.selectedRows}
              reload={this.getData}
              bust={this.bustCache}
              contentType={'plp'}
            />,
            this.props.context.flags.plpBulkInvoiceButtons && <InvoicingBulkAction key={"Invoicing"} selectedRows={this.state.selectedRows} contentType={'PLP'} userContext={this.userContext} reload={() => this.getData() } />
        ]
    }

    rowClassName = (record: any, index: number): string => {

        const validStatuses = [
            "NEW",
            "IN_PREPROCESSING",
            "PREPROCESSING_COMPLETE",
            "PENDING",
            "IN_TRANSLATION",
            "IN_PROOFREADING",
            "SIGN_OFF_WAITING",
            "IN_COPY_EDIT"
        ];

        let className = "";

        if ( validStatuses.indexOf( record.catToolStatus ) !== -1 ) {

            const
                now = DateTime.now(),
                SoD = DateTime.fromISO( record.latestTranslationDueTimestamp ).startOf('day'),
                EoD = DateTime.fromISO( record.latestTranslationDueTimestamp ).endOf('day');


            if ( now >= SoD && now <= EoD ) {
                className = `metaRow warning`
            }

            if ( now >= DateTime.fromISO( record.latestTranslationDueTimestamp) ) {
                className = `metaRow critical`
            }

        }
        return `${className} ${this.state.changedIds?.has(record._id) ? "rowUpdated" : "" }`
    }

    defaultDisabledColumns = [
        "helixId",
        "status",
        "projectTypes",
        "sharing",
        "stt",
        "dueDateTier",
        "owner",
        "workflowType",
        "expedited",
        "noExtension",
        "launched",
        "On hold",
        "invoiceMonth",
        "poNumber",
        "poIssued",
        "PODate",
        "salesOrder",
        "invoiceName",
        "costCode",
        "sourceLanguageCode",
        "languageCount",
        "notes",
        "totalMercuryCharge",
        "wordRate",
        "wordCharge",
        "serviceRate",
        "serviceCharge",
        "serviceHours",
        "dtpRate",
        "dtpHours",
        "dtpCharge",
        "engineeringCharge",
        "engineeringRate",
        "engineeringHours",
        "pmFeeRate",
        "pmFeeCharge",
        "twwc",
        "wc",
        "sharingStatus"
    ]

    pageTitle = "PLP search - Mercury";
    title = "PLPs";
    pageClass = "projectTable"

    public rowMap:Map<string, any> = new Map();

    public floodPrevention = () => {
        clearTimeout(this.floodTimer);
        this.floodTimer = undefined;
        this.setState({ rowData: Array.from( this.rowMap.values() ), changedIds: this.state.changedIds } );
    }

    public floodTimer?:number;

    async componentDidMount(): Promise<void> {

        await super.componentDidMount();

        const
            socket = this.props.context.gpSocket.plp;

        socket.on( "tail", (doc:any) => {

            this.rowMap.set( doc._id, doc );
            this.state.changedIds?.add( doc._id )

            const { rowData } = this.state;

            rowData.unshift( doc );

            this.rowMap.clear();

            rowData.forEach( ( row:any ) => this.rowMap.set( row._id, row ))

            this.setState({ rowData: Array.from( this.rowMap.values() ), changedIds: this.state.changedIds } );
        } )
    }

    componentDidUpdate(prevProps: TableProps<any>, prevState: TableState<any>) {
        super.componentDidUpdate(prevProps as any, prevState);
        if ( prevState.reqId !== this.state.reqId ) {
            const socket = this.props.context.gpSocket.plp;
            socket.off( `search:row:${prevState.reqId}` )
            socket.off( `search:meta:${prevState.reqId}` )
            socket.on( `search:row:${this.state.reqId}`, ( row:any ) => {

                const rows = Array.isArray( row ) ? row : [ row ];

                rows.forEach( ( item:any ) => {
                    this.rowMap.set( item._id, item );
                    socket.on( item._id, ( doc:any ) => {
                        this.state.changedIds?.add( doc._id )
                        if ( this.rowMap.has( doc._id ) ) {
                            this.rowMap.set( doc._id, doc );
                            if ( this.floodTimer ) {
                                clearTimeout(this.floodTimer);
                                this.floodTimer = undefined;
                            }
                            this.floodTimer = setTimeout( () => this.floodPrevention(), 500 ) as any;
                        }
                    })
                } )

                // socket.emit( "bind:ids", Array.from( this.rowMap.keys() ) )

                this.setState({ loading:false, changedIds: new Set(), rowData: Array.from( this.rowMap.values() ) } );
            } );

            socket.on( `search:meta:${this.state.reqId}`, ( meta:any ) => {
                this.setState({selectedRows: [], currentPage: meta.page, meta: meta })
            }  )
        }
    }

    componentWillUnmount() {
        const socket = this.props.context.gpSocket.plp;
        super.componentWillUnmount();
        socket.off( `search:row:${this.state.reqId}` )
        socket.off( `search:meta:${this.state.reqId}` )
        socket.off( `tail` );
        Array.from(( this.rowMap.keys() ) ).forEach( key => {
            socket.off( key );
        } );
    }

    async getData(): Promise<void> {

        const
            socket = this.userContext.gpSocket.plp,
            {sortOrder, sortField, currentPage, itemsPerPage, rowData} = this.state,
            ids = rowData.map( row => row._id);

        this.rowMap.clear();

        socket.emit( "unbind:ids",  ids );
        socket.emit( "search:abort" );

        ids.forEach( id => {
            socket.off( id );
        })

        this.setState( { reqId: uuid(), loading: true }, () => {
            const
                filters = this.filtersToQuery(),
                sort = {
                    [sortField ?? "importedTimestamp"]: sortOrder === "ascend" ? 1 : -1
                };

            if (
                sort.importedTimestamp === -1 &&
                Object.keys( filters ).length === 0 ||
                (
                    Object.keys( filters ).length === 1 &&
                    filters.importedTimestamp?.length > 1 &&
                    new Date( filters.importedTimestamp[1] ) > new Date()
                )
            ) {
                socket.emit( "tail", () => console.info( "tailing for new projects activated..."))
            }
            else {
                socket.emit( "untail", () => console.info( "tailing de-activated"))
            }


            socket.emit(`search`, {
                filter: this.filtersToQuery(),
                // filter: this.filters,
                sort: {
                    [sortField ?? "created_at"]: sortOrder === "ascend" ? 1 : -1
                },
                pagination: {
                    pageNumber: currentPage || 1,
                    resultsPerPage: itemsPerPage
                },
                reqId: this.state.reqId
            }, () => {
                this.setState({ loading: false, changedIds: new Set() } )
            });
        })
    }

    send(): Promise<void> {
        return Promise.resolve(undefined);
    }

}

const PLPSearchComponent = () => {
    const
        context = useMercuryContext(),
        breaks = useBreakpoint();

    return (<PLPSearch id={"PLPS"} context={context as any} useLegacyEndpoints={false} breaks={breaks as any}/>);
}

export default PLPSearchComponent;
