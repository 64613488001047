import {Select} from "antd";
import React, {useState, useEffect} from "react";
import SessionStorage from "../../utils/sessionStorage";
import {useMercuryContext} from "../../user-context";

const store = new SessionStorage( "projectTypesValues" );

export default function( props ) {
  const
    context = useMercuryContext(),
    [ TypeValues, setTypeValues ] = useState( [] ),
    values = props.value;

  useEffect( () => {
    if ( !store.get() && TypeValues.length === 0 ) {
      context.legacyClient.get("/projects/customVendorProjectMetadata.projectType/list")
        .then((res) => {
          store.set( res.data );
          setTypeValues(res.data);
        })
    }
    else {
      setTypeValues( store.data )
    }
    return;
  }, [context.legacyClient,TypeValues.length] )

  const data = TypeValues;
  return (
    <div id="productsFilter" className={"filter-wrapper"}>
      <label className="label">Project Type</label>
      <div style={{ display: "flex" }}>
        <Select
          style={{ width: "100%" }}
          allowClear
          autoClearSearchValue={false}
          placeholder="Please select"
          // value={values}
          onChange={( e ) => {
            return props.onChange( {
              target: {
                name: props.name ? props.name : "projectType",
                value: e
              } } )
          }}
        >
          { data && data.map( ( item ) => {
            return <Select.Option key={item} value={item ? item : ''}>
              {item}
            </Select.Option>
          })}
        </Select>
      </div>
    </div>
  )
}
