import React, {useState} from "react";
import Search from "antd/es/input/Search";
import "./projectId.scss";
import {Button, Tooltip, Select} from "antd";
import {CloseOutlined, InfoCircleOutlined} from "@ant-design/icons";

export default function ProjectIdFilter( props ) {

  const
    [ searchValue, setSearchValue ] = useState( "" ),
    inputs = props.value || [],
    values = Array.from( new Set( inputs ) );

  return (
    <div id="projectIdFilter" className={"filter-wrapper"}>
      <label className="label">
        <>
          Project IDS
          <Tooltip
            title={
              <div>
                <p style={{ marginBottom: "3px" }}>
                  Multiple IDs are allowed. Use comma,
                  semi-colon or space to separate.
                </p>
                <p style={{ marginBottom: "unset" }}>
                  <InfoCircleOutlined
                    style={{ marginRight: "5px" }}
                  />
                  Duplicate IDs will be ignored. Press enter to
                  submit.
                </p>
              </div>
            }
            placement="right"
            color={"var(--tertiary-color)"}
            overlayInnerStyle={{ width: "448px" }}
          >
            <InfoCircleOutlined
              style={{
                marginLeft: "5px",
                color: "var(--tertiary-color)",
                cursor: "help",
              }}
            />
          </Tooltip>
          {values.length > 1 && (
            <Tooltip title="Remove all Project IDS">
              <Button
                size={"small"}
                onClick={() => {
                  props.onClearFilter( {
                    target: {
                      name: props.name ? props.name : "projectIds",
                      value: []
                    } } )
                }}
                icon={<CloseOutlined />}
                className={"clear-projectId-btn"}
              ></Button>
            </Tooltip>
          )}
        </>
      </label>
      <Search
        name={props.name ? props.name : "projectIds" }
        placeholder={
          values.length === 0
            ? "Search Project IDS"
            : "Add more IDS"
        }
        disabled={props.disabled}
        onChange={(e)=> setSearchValue(e.target.value)}
        value={searchValue}
        onSearch={ ( e ) => {
          setSearchValue( "" );
          return props.onChange( {
            target: {
              name: props.name ? props.name : "projectIds",
              value: [ ...values, ...e.split(/[ ,;]+/) ]
            } } )
          }
        }
      />
      {
        values.length > 0 && <div className={"projectIdsFiltered"}>
          <Select
            showSearch
            mode={"multiple"}
            style={{width: "100%"}}
            placeholder={`${values.length} ids selected`}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.search(input) >= 0
            }
            onMouseDown={e => e.preventDefault()}
            value={[]}
          >
            <Select.Option disabled={true} value={""} onMouseDown={e => e.preventDefault()}>
              Click to remove an id
            </Select.Option>
            {
              Array.from( new Set( values ) ).map( ( id, index ) => {
                return <Select.Option key={index} value={id} onMouseDown={ ( e ) => {
                  e.preventDefault()
                  props.onRemove({
                    target: {
                      name: props.name ? props.name : "projectIds",
                      value: id
                    } })
                }}>
                  {id}
                </Select.Option>

              })
            }
        </Select>
        </div>
      }
    </div>
  )
}
