import React from "react";
import {useMercuryContext} from "../user-context";
import Table from "../components/abstracts/Table.abstract";
import {BlockOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import TimezoneConverter from "../timezone-converter";
import {Grid, Tooltip, Flex} from "antd";
import Text from "antd/es/typography/Text";

import ShortIdFilter from "../components/filters/projectId";
import VarStatusFilter from "../components/filters/varStatus";
import ProductsFilter from "../components/filters/product";
import ProductAreaFilter from "../components/filters/productArea";
import ReceivedDateFilter from "../components/filters/receivedDate";
import UrgencyFilter from "../components/filters/urgency"
import DeletedFilter from "../components/filters/deleted"
import ProjectTypeFilter from "../components/filters/projectType";
import ComponentTypeFilter from "../components/filters/componentType";
import InvoiceNumberFilter from "../components/filters/invoiceNumber";

import InvoiceButton from "../components/actions/invoicing/InvoiceButton";
import AssignInvoiceNumberButton from "../components/actions/invoicing/AssignInvoiceNumberButton";
import AccrualButton from "../components/actions/invoicing/AccrualButton";

import "./InvoicingPage.scss"
import EstimateButton from "../components/actions/invoicing/EstimateButton";
import RecoverProjectButton from "../components/actions/invoicing/RecoverProjectButton";
import BatchHours from "../Search/Batch.hours";

const { useBreakpoint } = Grid;

class InvoiceTable extends Table {
  pageClass="invoicing-page";
  method="POST";
  pageTitle="Invoicing - Mercury © RWS"
  title="Invoicing";
  endpoint() {
    return "/google-gp-v1/project/invoicing"
  }

  expandable = () => {
    console.log('Expandable not being used.')
  }

  columns() {
    return [
      {
        title: "Project ID",
        dataIndex: "shortId",
        sorter: true,
        width: 110,
        fixed: "left",
        render: (id, record) =>
          record.catToolProjectId !== null && (
            <>
            {
              record.deleted ?
              <Link
                to="#"
                id={id}
                className={"project-link"}
              >
                <BlockOutlined className="project-link-icon" />
              </Link>
              :
              <a
                href={`/invoicing-project/${id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BlockOutlined className="project-link-icon" />
              </a>

            }
            {
              !record.deleted ?

              <Link
                to={`/invoicing-project/${id}`}
                id={id}
                className={"project-link"}
              >
                {id}
              </Link>
              :
              <Link
                to="#"
                id={id}
                className={"project-link"}
              >
                {id}
              </Link>

            }
            </>
          ),
      },
      {
        title: "Title",
        dataIndex: "title",
        fixed: this.breaks.lg ? "left" : undefined,
        sorter: true,
        width: 320,
      },
      {
        title: "Product",
        dataIndex: "product",
        sorter: true,
        width: 150,
      },
      {
        title: "Component Type",
        dataIndex: "componentType",
        sorter: true,
        width: 130,
      },
      {
        title: "Urgency",
        dataIndex: "urgency",
        sorter: true,
        width: 150,
      },
      {
        title: "Project Type",
        dataIndex: "projectType",
        // sorter: true, <<< Not available due to nested field problem on docdb
        width: 150,
      },
      {
        title: "Received Date",
        dataIndex: "receivedDate",
        // sorter: true, <<< Not available due to nested field problem on docdb
        width: 170,
        render: (text) =>
          text != null ? (
            <Text>
              <TimezoneConverter date={text} />
            </Text>
          ) : (
            ""
          ),
      },
      // {
      //   title: "Target Languages",
      //   dataIndex: "targetLanguages",
      //   sorter: true,
      //   width: 100,
      // },
      // {
      //   title: "Due Date",
      //   dataIndex: "dueDate",
      //   sorter: true,
      //   width: 170,
      //   render: (text) =>
      //     text != null ? (
      //       <Text>
      //         <TimezoneConverter date={text} />
      //       </Text>
      //     ) : (
      //       ""
      //     ),
      // },
      // {
      //   title: "Delivered Date",
      //   dataIndex: "deliveredDate",
      //   sorter: true,
      //   width: 170,
      //   render: (text) =>
      //     text != null ? (
      //       <Text>
      //         <TimezoneConverter date={text} />
      //       </Text>
      //     ) : (
      //       ""
      //     ),
      // },
      {
        title: "Var Status",
        dataIndex: "varStatus",
        // sorter: true, <<< Not available due to nested field problem on docdb
        width: 180,
        render: (text, record) => {
          if ( !record.varStatus || record.varStatus.toLowerCase().includes( "failed" ) || record.varStatus.toLowerCase() === "unknown") {
            return (
              <Tooltip
                title={
                  "One of more PLPs in this project have invoicing issues."
                }
                placement="right"
                color={"var(--red)"}
              >
                {text}
                <InfoCircleOutlined
                  style={{ color: "var(--red)", marginLeft: "3px" }}
                />
              </Tooltip>
            )
          } else {
            return text;
          }
        },
      },
      {
        title: "Invoice Number",
        dataIndex: "invoiceName",
        // sorter: true, <<< Not available due to nested field problem on docdb
        width: 150,
      }
    ]
  }
  mapping = {
    plpReceivedDateMin: "receivedDate",
    plpDeliveredDateMin: "deliveredDate",
    translationDueTimestampMin: "dueDate",
    turnaroundTimeUrgency: "urgency"
  }
  filterComponents=[
    {
      name: "shortId",
      component: ShortIdFilter,
      left: true
    },
    {
      name: "varStatus",
      component: VarStatusFilter
    },
    {
      name: "invoiceName",
      component: InvoiceNumberFilter
    },
    {
      name: "projectType",
      component: ProjectTypeFilter
    },
    {
      name: "componentType",
      component: ComponentTypeFilter
    },
    {
      name: "product",
      component: ProductsFilter
    },
    {
      name: "productArea",
      component: ProductAreaFilter
    },
    {
      name: ["plpReceivedDateMin", "plpReceivedDateMax"],
      component: ReceivedDateFilter
    },
    {
      name: "turnaroundTimeUrgency",
      component: UrgencyFilter
    },
    {
      name: "deleted",
      component: DeletedFilter
    },


  ]
  disabledFilterRules(filters) {
    const disabledFilters = {};
    if ( filters.productArea ) {
      delete filters.product;
      disabledFilters.product = 1;
    }
    this.setState( { disabledFilters } );
    return filters;
  }

  renderActions() {
    return (
          <Flex wrap="wrap" gap="small">
            <RecoverProjectButton selectedRows={this.state.selectedRows} reload={this.getData} page={this} userAccess={this.state.userAccess}/>
            { this.props.flags.bulkSubmissionButton && <EstimateButton selectedRows={this.state.selectedRows} reload={this.getData} page={this} /> }
            { this.props.flags.bulkSubmissionButton && <AccrualButton selectedRows={this.state.selectedRows} reload={this.getData} page={this} /> }
            <AssignInvoiceNumberButton selectedRows={this.state.selectedRows} reload={ () => this.getData.call( this )} />
            <BatchHours
              selectedRows={this.state.selectedRows}
              reload={this.getData}
              bust={this.bustCache}
              contentType={'invoice'}
            />
            <InvoiceButton selectedRows={this.state.selectedRows} reload={this.getData} page={this} />
          </Flex>  
    )
  }
  body() {
    if ( this.method.toUpperCase() === "GET" ) {
      return {}
    }
    const payload = {
      filter: {
        ...{
          catToolStatus: "",
          componentType: "",
          dueDateTier: "",
          invoiceName: "",
          month: "",
          plpDeliveredDateMax: "",
          plpDeliveredDateMin: "",
          plpReceivedDateMax: "",
          plpReceivedDateMin: "",
          product: "",
          productArea: "",
          projectType: "",
          serviceType: "",
          shortId: "",
          translationDueTimestampMax: "",
          translationDueTimestampMin: "",
          turnaroundTimeUrgency: "",
          varSubmittedDateMax: "",
          varSubmittedDateMin: "",
          varStatus: "",
          year: "",
          deleted: ""
        },
        ...this.filters
      },
      pagination: {
        pageNumber: this.currentPage,
        resultsPerPage: this.itemsPerPage
      },
      sort: {
        sortOrder: this.sortOrder,
        sortField: this.sortField
      }
    }

    return payload;
  }
}

export default () => {

  const
    context = useMercuryContext(),
    breaks = useBreakpoint();

  return <InvoiceTable
      flags={context.flags}
      context={context}
      breaks={breaks}
      useLegacyEndpoints={false}
      useGpEKS={true}
      />;
}
