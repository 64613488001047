import {Select} from "antd";
import React, {useState, useEffect} from "react";
import SessionStorage from "../../utils/sessionStorage";
import {useMercuryContext} from "../../user-context";

const store = new SessionStorage( "varStatusValues" );

export default function( props ) {
  const
    context = useMercuryContext(),
    [ statusValues, setStatusValues ] = useState( [] ),
    values = props.value || [];

  useEffect( () => {
    if ( !store.data && statusValues.length === 0 ) {
      context.legacyClient.get("/projects/status/list")
        .then((res) => {
          store.data = res.data;
          setStatusValues(res.data);
        })
    }
    else {
      setStatusValues( store.data )
    }
    return;
  }, [ statusValues.length, context.legacyClient])

  const data = statusValues;

  return (
    <div id="varStatusFilter" className={"filter-wrapper"}>
      <label className="label">VAR Status</label>
      <Select
        name={props.name ? props.name : "varStatus" }
        allowClear
        className="project-type"
        onChange={( e ) => {
          props.onChange({
            target: {
              name: props.name ? props.name : "varStatus",
              value: e
            }
          })
        }}
        value={values}
        placeholder="Please select"
      >
        {
          data && data.map((item, i) => {
            return (
              <Select.Option key={i} value={item}>
                {item}
              </Select.Option>
            )
          })
        }
      </Select>
    </div>
  )
}
