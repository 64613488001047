import React, {useState} from "react";
import {Button, Modal, Tooltip} from "antd";

import InvoiceSubmissionModal from "./InvoiceSubmissionModal";
import {useMercuryContext} from "../../../user-context";

export default function InvoiceButton({selectedRows, reload, page}) {

    const
        context = useMercuryContext(),
        rows = selectedRows || [],
        allowedSubmitCriteria = (element) => element.varStatus === "INVOICE_FAILED" || element.varStatus === "ACCRUAL",
        allowSubmit = rows.length > 0 && rows.filter( ( e ) => e.deleted !== true ).filter(allowedSubmitCriteria).length,
        [isLoading, setLoading] = useState(false),
        [isModalVisible, setIsModalVisible] = useState(false),
        [Submitting, setInvoiceSubmission] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setInvoiceSubmission(true)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            {
                Submitting &&
                <InvoiceSubmissionModal context={context} show={Submitting} rows={rows} loadingHandle={setLoading}
                                        close={() => {
                                            setInvoiceSubmission(false)
                                            reload.call(page);
                                        }}/>
            }
            <Modal title="Submit Invoice" open={isModalVisible} onOk={() => {
                handleOk()
            }} onCancel={handleCancel}>
                <p>This action cannot be undone, are you sure you'd like to continue?</p>
            </Modal>
            <Tooltip
                title={!allowSubmit ? "Select at least 1 item" : ""}
                placement="bottom"
                color={"var(--tertiary-color)"}
            >
                <Button
                    onClick={showModal}
                    loading={isLoading}
                    disabled={!allowSubmit}
                    danger
                    type="primary">
                    Invoice
                </Button>
            </Tooltip>
        </>
    )

}
