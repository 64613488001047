import { TableAbstract, ListFilter, CustomTooltip } from "../../Components";

import { useMercuryContext } from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import TimezoneConverter from "../../timezone-converter";
import { Link } from "react-router-dom";
import { Space } from "antd";
import SesameDropdown from "../../stories/Sesame/Dropdown/SesameDropdown";
import SesameAccountDialog from "../../stories/SesameAccount/SesameAccountDialog";
import { failedNotification, successfulNotification } from "../../utils/notifications";

class SesameSearch extends TableAbstract<any> {
  defaultSortField = "requestedDate";
  FilterComponents = [
    {
      id: "role",
      name: "role",
      title: "Program Role",
      component: ListFilter,
      active: true,
    },
    {
      id: "code",
      title: "Language Code",
      name: "code",
      component: ListFilter,
      active: false,
    },
    {
      id: "affiliation",
      title: "Affiliation",
      name: "affiliation",
      component: ListFilter,
      active: false,
    },
    {
      id: "productLine",
      title: "Product Line",
      name: "productLine",
      component: ListFilter,
    },
    {
      id: "emailAddress",
      title: "Email Address",
      name: "emailAddress",
      component: ListFilter,
    },
  ];

  commonFields = (id: string, title: string) => {
    return {
      id,
      dataIndex: id,
      title,
      sorter: true,
      fixed: undefined,
      width: 150,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    };
  };

  columns = [
    {
      id: "_id",
      title: "ID",
      dataIndex: "_id",
      width: 200,
      fixed: undefined,
      render: (id: string, record: any) => (
        <Link to={`/sesame/${id}`} id={id}>
          {id}
        </Link>
      ),
    },
    {
      id: "requestedDate",
      title: "Requested Date",
      dataIndex: "requestedDate",
      width: 180,
      fixed: undefined,
      render: (text: string) => <TimezoneConverter date={text} />,
    },
    {
      id: "requestType",
      title: "Request Type",
      dataIndex: "requestType",
      sorter: true,
      fixed: undefined,
      width: 150,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "requesterEmail",
      title: "Requester Email",
      dataIndex: "requesterEmail",
      sorter: true,
      fixed: undefined,
      width: 200,
      render: (text: string) => <CustomTooltip text={text} length={25} />,
    },
    {
      ...this.commonFields("role", "Program/Project Role"),
    },
    {
      ...this.commonFields("languageCode", "Language Code"),
    },
    {
      ...this.commonFields("affiliation", "Affiliation"),
    },
    {
      ...this.commonFields("productLine", "Product Line"),
    },
    {
      ...this.commonFields("contentType", "Content Type"),
    },
    {
      ...this.commonFields("fileSharing", "Automated file sharing in Polyglot"),
    },
    {
      ...this.commonFields(
        "emailCommunications",
        "Include in HO emails and other email communications"
      ),
    },
    {
      ...this.commonFields("fullName", "Full Name"),
    },
    {
      ...this.commonFields("city", "City, Country"),
    },
    {
      id: "emailAddress",
      title: "Email ID of new resource",
      dataIndex: "emailAddress",
      sorter: true,
      fixed: undefined,
      width: 180,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "notes",
      title: "Additional Instructions for Training team",
      dataIndex: "notes",
      sorter: true,
      fixed: undefined,
      width: 220,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
  ];

  async handleSubmit(data: any, callback: any) {
    if (data?.requestType === "newSesame") {
      try {
        const res = await this.userContext.gpClient.post(
          `/google-gp-v1/sesameAccounts/newRequest`,
          data,
        );
        callback();
        successfulNotification("Success",  `Sesame Id ${res?.data?.primaryEmail} was created`)
      } catch (error: any) {
        callback();
        failedNotification("Error",  `${error?.response?.data?.details?.split("\n")?.[0] || error?.response?.data?.message}`)
      }
    }
  }

  renderActions() {
    return (
      <Space size={"large"}>
        <SesameAccountDialog
          onSubmit={(data, callback) => this.handleSubmit(data, callback)}
          requesterEmail="test@gmail.com"
          buttonLabel="Create"
        />
        <SesameDropdown buttonLabel="Alibaba 2.0" onSelect={() => {}} />
      </Space>
    );
  }

  async fetch() {
    const { sortOrder, sortField } = this.state;

    const res = await this.userContext.gpClient.post(
      `/google-gp-v1/sesameAccounts/search`,
      {
        filter: this.filtersToQuery(),
        sort: {
          [sortField || "lastUpdatedTimestamp"]:
            sortOrder === "ascend" ? 1 : -1,
        },
        pagination: {
          pageNumber: this.currentPage || 1,
          resultsPerPage: this.itemsPerPage,
        },
      }
    );
    return { data: res.data } as any;
  }

  pageTitle = "Sesame Tracker";
  title = "Sesame Tracker";
  pageClass = "projectTable";
}

const SesameTable = () => {
  const context = useMercuryContext(),
    breaks = useBreakpoint();

  return (
    <SesameSearch
      id="Sesame Tracker"
      noSelect={true}
      context={context}
      useLegacyEndpoints={false}
      breaks={breaks as any}
    />
  );
};

export default SesameTable;
