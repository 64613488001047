import {Select} from "antd";
import React, {useState, useEffect} from "react";
import SessionStorage from "../../utils/sessionStorage";
import {useMercuryContext} from "../../user-context";

const store = new SessionStorage( "componentTypesValues" );

export default function( props ) {
  const
    context = useMercuryContext(),
    [ loading, setLoadingState ] = useState( true ),
    [ errored, setErrorState ] = useState( false ),
    [ TypeValues, setTypeValues ] = useState( [] ),
    values = props.value;

  useEffect( () => {
    if ( !store.data && TypeValues.length === 0 ) {
      context.legacyClient.get("/projects/componentType/list")
        .then((res) => {
          store.data = res.data;
          setTypeValues(res.data);
          setLoadingState( false );
          setErrorState( false );
        })
        .catch( ( e ) => {
          console.error( e );
          setLoadingState( false );
          setErrorState( true );
        })
    }
    else {
      setTypeValues( store.data )
      setLoadingState( false )
    }
    return;
  }, [context.legacyClient,TypeValues.length])

  const data = TypeValues;

  return (
    <div id="componentsFilter" className={"filter-wrapper"}>
      <label className="label">Component Type</label>
      <div style={{ display: "flex" }}>
        <Select
          style={{ width: "100%" }}
          loading={loading}
          status={errored ? "error" : undefined }
          allowClear
          placeholder="Please select"
          value={values}
          onChange={( e ) => {
            return props.onChange( {
              target: {
                name: props.name ? props.name : "componentType",
                value: e
              } } )
          }}
        >
          { data && data.map( ( item ) => {
            return <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          })}
        </Select>
      </div>
    </div>
  )
}
