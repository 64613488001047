import React, {useState} from "react";
import {Button, Tooltip} from "antd";
import EstimateSubmissionModal from "./EstimateSubmissionModal";
import {useMercuryContext} from "../../../user-context";

export default function EstimateButton( { selectedRows, reload, page } ) {

    const
        context = useMercuryContext(),
        rows = selectedRows || [],
        states = [ "ESTIMATE", "ESTIMATE_FAILED", "NEW", "UNKNOWN", "ACCRUAL", "ACCRUAL_FAILED" ],
        allowedSubmitCriteria = (element) => states.indexOf( element.varStatus ) !== -1,
        allowSubmit = rows.length > 0 && rows.filter( ( e ) => e.deleted !== true ).filter(allowedSubmitCriteria).length,
        [isLoading, setLoading] = useState( false ),
        [Submitting, setInvoiceSubmission] = useState(false);

    const handleOk = () => {
        setInvoiceSubmission( true )
    };

    return (
        <>
            {
                Submitting && <EstimateSubmissionModal context={context} show={Submitting} rows={rows} loadingHandle={setLoading} close={() => {
                    setInvoiceSubmission( false )
                    reload.call(page);
                } } />
            }
            <Tooltip
                title={!allowSubmit ? "Select at least 1 item" : ""}
                placement="bottom"
                color={"var(--tertiary-color)"}
            >
                <Button
                    onClick={handleOk}
                    loading={isLoading}
                    disabled={!allowSubmit}
                    type="primary">
                    Estimate
                </Button>
            </Tooltip>
        </>
    )

}
