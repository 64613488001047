import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Button } from "antd";
import type { MenuProps } from "antd";

interface DropdownProps {
  buttonLabel: string;
  onSelect: (key: string) => void;
}

const items: MenuProps["items"] = [
  {
    key: "1",
    label: "Remove Exception Group",
  },
  {
    key: "2",
    label: "Create new account(s)",
    children: [
      {
        key: "2-1",
        label: "Linguist/Lead",
      },
      {
        key: "2-2",
        label: "Tester",
      },
      {
        key: "2-3",
        label: "PM/TM/TS/Test Lead",
      },
      {
        key: "2-4",
        label: "Life Sciences",
      },
    ],
  },
  {
    key: "3",
    label: "Reset Password",
  },
  {
    key: "4",
    label: "Account Suspension",
    children: [
      {
        key: "4-1",
        label: "Suspend account(s)",
      },
      {
        key: "4-2",
        label: "Activate account(s)",
      },
    ],
  },
];

const SesameDropdown: React.FC<DropdownProps> = ({ buttonLabel, onSelect }) => {
  const handleClick: MenuProps["onClick"] = (e) => {
    onSelect(e.key);
  };

  return (
    <Dropdown
      menu={{ items, onClick: handleClick }}
      overlayStyle={{ zIndex: 999999999 }}
    >
      <Button>
        <Space>
          {buttonLabel}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default SesameDropdown;
