import { Switch, Tooltip } from "antd";
import React from "react";

export default function( props ) {

  return (
    <div id="deletedFilter" className={"filter-wrapper"}>
      <label className="label">Deleted Projects</label>
      <Tooltip
        title="Show deleted projects"
        placement="bottom"
        color={"var(--tertiary-color)"}
      >
        <Switch
          placeholder="Please select"
          className="deleted"
          style={{maxWidth: '50px'}}
          checked={props.value ? props.value : false}
          onChange={( e ) => {
            props.onChange({
              target: {
                name: props.name ? props.name : "deleted",
                value: e
              }
            })
          }}
        />
      </Tooltip>
    </div>
  )
}
