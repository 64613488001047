import {Select} from "antd";
import React, {useState, useEffect} from "react";
import SessionStorage from "../../utils/sessionStorage";
import {useMercuryContext} from "../../user-context";

const store = new SessionStorage( "turnaroundTimeUrgencyValues" );

export default function( props ) {
  const
    context = useMercuryContext(),
    [ TypeValues, setTypeValues ] = useState( [] ),
    [ loading, setLoadingState ] = useState( true ),
    [ errored, setErrorState ] = useState( false ),
    values = props.value || [];

  useEffect( () => {
    if ( !store.data && TypeValues.length === 0 ) {
      context.legacyClient.get("/projects/turnaroundTimeUrgency/list")
        .then((res) => {
          store.data = res.data;
          setTypeValues(res.data);
          setLoadingState( false );
          setErrorState( false );
        })
    }
    else {
      setTypeValues( store.data );
      setLoadingState( false );
    }
    return;
  }, [TypeValues.length,context.legacyClient])

  const data = TypeValues;

  return (
    <div id="urgencyFilter" className={"filter-wrapper"}>
      <label className="label">Urgency</label>
      <Select
        allowClear
        loading={loading}
        status={errored ? "error" : undefined }
        placeholder="Please select"
        className="urgency"
        onChange={( e ) => {
          props.onChange({
            target: {
              name: props.name ? props.name : "turnaroundTimeUrgency",
              value: e
            }
          })
        }}
        value={values}
      >
        {
          data && data.map((item, i) => {
            return (
              <Select.Option key={i} value={item}>
                {item}
              </Select.Option>
            )
          })
        }
      </Select>
    </div>
  )
}
