import React from "react";
import AccrualSubmissionModal from "./AccrualSubmissionModal";
import EstimateSubmission from "./EstimateSubmission";

export default class EstimateSubmissionModal extends AccrualSubmissionModal {

    title = "Estimate submission";

    warning = <p>Submitting estimates. <strong>Please do not close the window or navigate away from the page until the process is complete.</strong></p>;

    async componentDidMount() {

        const ids = await this.listPlps( this.props.rows )

        this.setState( { loading: false } );

        const Submitter = this.submitter = new EstimateSubmission( {
            context: this.props.context,
            projects: ids
        });

        Submitter.on( "update", ( s ) => {
            this.setState({ rows: s } );
        }  );

        await Submitter.submit();
    }
}
