import React, {useState} from "react";
import {Button, Form, Input, Modal, Tooltip} from "antd";
import {failedNotification,} from "../../../utils/notifications";
import axios from "axios";
import {BASE_URL} from "../../../utils/variables";
import {useMercuryContext} from "../../../user-context";
import ConcurrentTaskQueue from "../../../utils/ConcurrentTaskQueue";

const InvoiceNumberForm = ( { selectedRows, reload }) => {

  const context = useMercuryContext();
  const { accessToken } = context;
  const [ visible, setVisible ] = useState( false );
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  async function onSubmit( values ) {
    try {

      // Submit the form
      let headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const Queue = new ConcurrentTaskQueue( 5 );

      Queue.on( "complete", () => {
        setVisible( false );

        if ( reload ) {
          reload();
        }

        // successfulNotification(
        //   `Project Invoice Number updated`,
        //   `The Invoice Number for the selected projects has been updated`
        // );

        setConfirmLoading(false);
        form.resetFields();

      });

      return Queue.add( selectedRows.map((project) => {
         return async () => {
          try {
            await axios
              .post(
                `${BASE_URL}/project/${project._id}/invoiceName`,
                values,
                {
                  headers: headers,
                }
              )
          } catch ( error ) {
            if (error.response) {
              failedNotification(
                error.response.data.error,
                error.response.data.detail
              );
            } else {
              failedNotification("Failed to update the Invoice");
            }
          }
        }
      } ) );

    } catch (error) {
      failedNotification("Failed to update the Invoices");
    }
  }

  if ( selectedRows.filter( ( e ) => e.deleted !== true ).length === 0 ) {
    return (
      <Tooltip
        title="Select at least 1 row"
        placement="bottom"
        color={"var(--tertiary-color)"}
      >
        <Button
          disabled
          style={{ borderColor: "var(--tertiary-color)" }}
        >
          Assign Invoice
        </Button>
      </Tooltip>
    )
  }

  return (
    <>
      <Button
        onClick={() => setVisible( !visible )}
        type="primary"
      >
        Assign Invoice
      </Button>
      <Modal
        open={visible}
        title="Invoice Number"
        okText="OK"
        cancelText="Cancel"
        onCancel={ () => setVisible( false ) }
        onOk={async () => {
          try {
            if ( await form.validateFields() ) {
              let
                values = form.getFieldsValue();
              setConfirmLoading(true);
              await onSubmit( values);
            }
          } catch (error) {
            console.error("Validate Failed:", error);
          }
        }}
        okButtonProps={{
          disabled: confirmLoading
        }}
        confirmLoading={confirmLoading}
      >
        <Form
          form={form}
          layout="vertical"
          name="invoiceNumberForm"
        >
          <Form.Item
            name="invoiceName"
            label="Assign Invoice Number"
            rules={[{ required: false, message: "Please enter a Invoice Number" }]}
          >
            <Input disabled={confirmLoading} />
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
};

export default InvoiceNumberForm;
