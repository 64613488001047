import datePickerRanges from "../../utils/datePickerRanges";
import React from "react";
import {DatePicker} from "antd";
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

export default function( props ) {

  return (
    <div id="receivedDateFilter" className={"filter-wrapper"}>
      <label className="label">
        Received Date
      </label>
      <RangePicker
        className="received-date-picker"
        presets={datePickerRanges}
        onChange={( e ) => {
          props.onChange(props.name.map( ( name, i ) => {
            return {
              target: {
                name: name,
                value: e && e[i] ? dayjs(e[i]).format("YYYY-MM-DDT00:00:00") : undefined
              }
            }
          }))
        }}
        value={props.value.map( ( date ) => {
          if ( date && date.length > 0 ) {
            return dayjs( new Date(date) );
          }
          return undefined
        })}
        format="DD-MM-YYYY"
      />
    </div>
  )
}
