import InvoiceSubmission from "./InvoiceSubmission";
import {BASE_URL} from "../../../utils/variables";
import ConcurrentTaskQueue from "../../../utils/ConcurrentTaskQueue";

export default class AccrualSubmission extends InvoiceSubmission {
    url = ( id, plp ) => `${BASE_URL}/plp/${plp}/submitAccrual`

    async send( project, plp ) {
        return this.Client.post( this.url( project.shortId, plp ), {} );
    }

    ignoreProject( project ) {
        return false;
    }

    async submitProject(project) {
        this.updateProject({...project, errored: 0})
        await new Promise((accept) => {
            const Queue = new ConcurrentTaskQueue(process.env.REACT_APP_INVOICE_SUBMISSION_CONCURRENCY || 1);
            Queue.on("complete", () => {
                const updated = this.projects.get(project.shortId);
                if ( updated.state === "Errored"  && updated.attempts < 2) {
                    updated.attempts++
                    const retry = {...updated, state: "Errored", result: "Retrying...", errored: 0, completed: 0, attempts: updated.attempts };
                    this.updateProject(retry);
                    this.submitProject(retry);
                    return accept();
                }
                else {
                    updated.sent = true;
                }
                this.updateProject(updated);
                return accept();
            });

            Queue.add(project.plps.map((plp) => {

                return async () => {
                    await new Promise((accept) => {

                        this.updateProject( { ...project, state: "Submitting"})

                        this.send( project, plp )
                            .then((response) => {

                                project.completed++;
                                this.updateProject({
                                    ...project,
                                    state: project.errored ? `Errored` : project.completed === project.total ? "Complete" : "Submitting",
                                    result: project.errored ? `Submitted ${project.completed} of ${project.total}` : project.completed === project.total ? "Success" : "Pending",
                                    sent: project.completed === project.total
                                });

                                return accept();
                            })
                            .catch((error) => {
                                if (error.response) {
                                    this.updateProject({
                                        ...project,
                                        state: "Errored",
                                        result: `Submitted ${project.completed} of ${project.total}`,
                                        errored: project.errored++
                                    });

                                } else {
                                    this.updateProject({
                                        ...project,
                                        state: "Errored",
                                        result: `An unknown error occurred, will retry.`,
                                        errored: project.errored++
                                    });
                                }
                                return accept();
                            })
                    })
                }
            }))
        })
    }
}
