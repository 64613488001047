import React, {useState, useEffect} from "react";
import Search from "antd/es/input/Search";

export default function( props ) {

  const
    [searchValue, setSearchValue] = useState(props.value);

  useEffect( () => {
    setSearchValue( props.value )
  }, [props.value])

  return (
    <div id="invoiceNumberFilter" className={"filter-wrapper"}>
      <label className="label">Invoice Number</label>
      <Search
        placeholder="Search Invoice Number"
        value={searchValue}
        name={props.name ? props.name : "invoiceName"}
        allowClear
        onChange={(e)=> setSearchValue(e.target.value)}
        onSearch={ ( e ) => {
          return props.onChange( {
            target: {
              name: props.name ? props.name : "invoiceName",
              value: e
            } } )
        }}
      />
    </div>
  )
}
